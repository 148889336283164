import React from "react"
import Router from "./Router"
import "./components/@vuexy/rippleButton/RippleButton"
import GetMetamask from './components/GetMetamask';
import tokenContract from './assets/contract/Genobank.json';
import web3 from './utility/web3';
import Swal from 'sweetalert2';
import ipfs from './utility/ipfs';

import "react-perfect-scrollbar/dist/css/styles.css"
import "prismjs/themes/prism-tomorrow.css"

export const AppContext = React.createContext();

const web3networks = {
  1: {
      name: 'Ethereum Mainnet',
      etherscanUrl: 'https://etherscan.io/tx/'
  },
  3: {
      name: 'ropsten',
      etherscanUrl: 'https://ropsten.etherscan.io/tx/'
  },
  4: {
      name: 'Rinkeby Testnet',
      etherscanUrl: 'https://rinkeby.etherscan.io/tx/'
  },
  42: {
      name: 'kovan',
      etherscanUrl: 'https://kovan.etherscan.io/tx/'
  }
};

class App extends React.Component {
  static exists(s) {
    let chk = (s ? s.toString() : '');
    return chk && chk.trim().length > 0;
  }

  static clear() {
      window.location.reload();
  }

  constructor(props) {
      super(props);

      this.state = {
          web3ctx: {
              networkId: '',
              networkName: '',
              etherscanUrl: '',
              activeWallet: '',
              lastBlockNumber: '',
              currentBalance: '',
              tokenAddress: '0xe15225912dd58892283b2606e096184889eb3d86',
              ipfsGateway: 'https://ipfs.infura.io/ipfs/'
          },
          myToken: {
              address: '0xe15225912dd58892283b2606e096184889eb3d86',
              abi: tokenContract.abi,
              imageBuffer: '',
              ipfsImageHash: '',
              ipfsImageUrl: '',
              metadataBuffer: '',
              ipfsMetadataHash: '',
              ipfsMetadataUrl: '',
              recipientAddress: '',
              newMinterAddress: '',
              txHash: '',
              txReceipt: '',
              blockNumber: '',
              gasUsed: ''
          },
          actions: {
              assignProp: this.assignProp.bind(this),
              recipientActive: this.recipientActive.bind(this),
              assignPropToken: this.assignPropToken.bind(this),
              captureFile: this.captureFile.bind(this),
              handleAbiChange: this.handleAbiChange.bind(this),
              addIpfs: this.addIpfs.bind(this),
              mintToken: this.mintToken.bind(this)
          }
      };
  }

  checkNetwork = async () => {
    if (!web3 || typeof web3 == 'undefined' || web3.version.network === 'loading') {}
    else {
        // first update the values that can change while connected
        let myContext = this.state.web3ctx;
        let accounts = await web3.eth.getAccounts();
        myContext.activeWallet = accounts[0];
        myContext.lastBlockNumber = await web3.eth.getBlockNumber();
        console.log(accounts);
      
        //myContext.currentBalance = await web3.eth.getBalance(accounts[0]);

        await web3.eth.net.getId((err, netId) => {
          console.log(err, netId);

            if (netId && this.state.web3ctx.networkId !== netId) {
                // we're on a new network, look for deployed contract
                console.log('refreshing settings for network ' + netId);

                myContext.networkId = netId;
                myContext.networkName = (web3networks[netId] ? web3networks[netId].name : 'unknown');
                myContext.etherscanUrl = (web3networks[netId] ? web3networks[netId].etherscanUrl : 'unknown');

                if (tokenContract.networks[netId]) {
                    // attempt to load contract address deployed on this network
                    let newAddress = (tokenContract.networks[netId].address ? tokenContract.networks[netId].address : '');

                    console.log('Using contract at address \'' + newAddress + '\'');
                    myContext.tokenAddress = newAddress;
                } else {
                    console.log('No contract deployed on network ' + netId);
                    myContext.tokenAddress = '';
                }
            }
        });

        await this.setState({web3ctx: {...myContext}});

        await this.setState({
            myToken: {
                ...this.state.myToken,
                recipientAddress: accounts[0]
            }
        });

        Swal.fire({
            title: 'Good job!',
            customClass: {
                popup: 'backgroundDarker',
            },
            html: '<p>Connected to ' + this.state.web3ctx.networkName + '</p>',
            icon: 'success'
        });
    }
  };

  componentDidMount() {
      this.checkNetwork();
  }

  captureFile = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const file = e.target.files[0];
      let reader = new window.FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader)
  };

  convertToBuffer = async (reader) => {
      // file is converted to a imageBuffer to prepare for uploading to IPFS
      const myBuffer = await Buffer.from(reader.result);
      // set this imageBuffer -using es6 syntax
      this.setState({
          myToken: {
              ...this.state.myToken,
              imageBuffer: myBuffer
          }
      });
  };

  handleAbiChange(e) {
    this.setState({
        myToken: {
            ...this.state.myToken,
            abi: JSON.parse(e.target.value)
        }
    });
  }

  addIpfs = async () => {
      // save document to IPFS,return its hash#, and set hash# to state
      // https://github.com/ipfs/interface-ipfs-core/blob/master/SPEC/FILES.md#add
      // setState by setting ipfsImageHash to ipfsImageHash[0].hash
      if (this.state.myToken.imageBuffer && this.state.myToken.imageBuffer.length > 0) {
        Swal.fire({
            title: 'Loading...',
            customClass: {
                popup: 'backgroundDarker',
            },
            html:
                '<video width="100%" loop autoplay>' +
                    '<source src="/video/Loading.mp4" type="video/mp4">' +
                    'Your browser does not support the video tag.' +
                '</video>',
            showCloseButton: false,
            showConfirmButton: false,
            showCancelButton: false,
            backdrop: false
          });

        await ipfs.add(this.state.myToken.imageBuffer, (err, ipfsHash) => {
            console.log('IPFS hash: ' + ipfsHash[0].hash + ', error: ' + err);

            let imageUrl = this.state.web3ctx.ipfsGateway + ipfsHash[0].hash;

            Swal.close();

            this.setState({
                myToken: {
                    ...this.state.myToken,
                    ipfsImageHash: ipfsHash[0].hash,
                    ipfsImageUrl: imageUrl,
                    imageBuffer: ''
                }
            });
        })
      } else if (this.state.myToken.metadataBuffer && this.state.myToken.metadataBuffer.toString().length > 0) {
        var buffer = Buffer.from(this.state.myToken.metadataBuffer, 'utf8');

        Swal.fire({
            title: 'Uploading Metadata to IPFS...',
            customClass: {
                popup: 'backgroundDarker',
            },
            html:
                '<video width="100%" loop autoplay>' +
                    '<source src="/video/Loading.mp4" type="video/mp4">' +
                    'Your browser does not support the video tag.' +
                '</video>',
            showCloseButton: false,
            showConfirmButton: false,
            showCancelButton: false,
            backdrop: false
          });

        await ipfs.add(buffer, (err, ipfsHash) => {
            console.log('IPFS hash: ' + ipfsHash[0].hash + ', error: ' + err);

            Swal.close();

            this.setState({
                myToken: {
                    ...this.state.myToken,
                    ipfsMetadataHash: ipfsHash[0].hash,
                    ipfsMetadataUrl: this.state.web3ctx.ipfsGateway + ipfsHash[0].hash
                }
            });
        })
      } else {
          console.log('file could not be found: '
              + JSON.stringify(this.state.myToken.metadataBuffer, null, 2));
          return 1;
      }
  };

  assignProp(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      web3ctx: {
          ...this.state.web3ctx,
          [name]: value
      }
    });
  }

  assignPropToken(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      myToken: {
          ...this.state.myToken,
          [name]: value
      }
    });
  }

  recipientActive () {
    this.setState({
        myToken: {
            ...this.state.myToken,
            recipientAddress: this.state.web3ctx.activeWallet
        }
      });
  }

  mintToken = async () => {
    if (!App.exists(this.state.myToken.abi) ||
        !App.exists(this.state.web3ctx.tokenAddress) ||
        !App.exists(this.state.myToken.recipientAddress) ||
        !App.exists(this.state.myToken.ipfsImageUrl) ||
        !App.exists(this.state.myToken.ipfsMetadataUrl)) {
        console.log('Required input fields are missing!');
        return false;
    }

    try {
        this.setState({
            myToken: {
                ...this.state.myToken,
                blockNumber: 'waiting..',
                gasUsed: 'waiting...'
            }
        });

        // bring in user's metamask account address
        const accounts = await web3.eth.getAccounts();
        const myTokenInstance = new web3.eth.Contract(
            this.state.myToken.abi,
            this.state.web3ctx.tokenAddress
        );

        console.log('Sending from Metamask account: ' + accounts[0] + ' to token address '
            + myTokenInstance.options.address);

        // see, this https://web3js.readthedocs.io/en/1.0/web3-eth-contract.html#methods-mymethod-send
        await myTokenInstance.methods.createSample(this.state.myToken.recipientAddress, this.state.myToken.ipfsMetadataUrl).send({
            from: accounts[0]
        }, (error, txHash) => {
            console.log('txHash: ' + txHash + ', error: ' + error);        
            
            if (txHash !== undefined) {
                Swal.fire(
                    'Successful Token Creation!',
                    '<a style="color:black" href="' + this.state.web3ctx.etherscanUrl + txHash + '" target="_assignPropToken rel="noopener noreferrer">TX: ' + txHash + '</a>',
                    'success'
                )
            }

            this.setState({
                myToken: {
                    ...this.state.myToken,
                    txHash: txHash
                }
            });
        });

        // get Transaction Receipt in console on click
        // See: https://web3js.readthedocs.io/en/1.0/web3-eth.html#gettransactionreceipt
        await web3.eth.getTransactionReceipt(this.state.myToken.txHash, (err, txReceipt) => {
            if (txReceipt) {
                this.setState({
                    myToken: {
                        ...this.state.myToken,
                        txReceipt: txReceipt
                    }
                });
            }
        });

    } catch (e) {
        console.log('Error: ' + e);
    }
  };

  render() {
    if (!web3 || typeof web3 == 'undefined' || web3.version.network === 'loading') {
      return (
          <GetMetamask/>
      );
    } else {
      return (
        <AppContext.Provider
          value={{
              web3ctx: {...this.state.web3ctx},
              myToken: {...this.state.myToken},
              actions: {...this.state.actions}
          }}>
          <Router />
        </AppContext.Provider>
      )
    }
  }
}

export default App
