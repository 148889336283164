import React from "react"
import { Navbar } from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { AppContext } from "../../../App";
import {
  logoutWithJWT,
  logoutWithFirebase
} from "../../../redux/actions/auth/loginActions"
import logoGeno from "../../../assets/img/logo/logoGeno.png"

const ThemeNavbar = props => {
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  return (
    <AppContext.Consumer>
        {({web3ctx, myToken, actions}) => (
          <React.Fragment>
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <Navbar
              className={classnames(
                "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
                {
                  "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
                  "navbar-dark": colorsArr.includes(props.navbarColor),
                  "bg-primary":
                    props.navbarColor === "primary" && props.navbarType !== "static",
                  "bg-danger":
                    props.navbarColor === "danger" && props.navbarType !== "static",
                  "bg-success":
                    props.navbarColor === "success" && props.navbarType !== "static",
                  "bg-info":
                    props.navbarColor === "info" && props.navbarType !== "static",
                  "bg-warning":
                    props.navbarColor === "warning" && props.navbarType !== "static",
                  "bg-dark":
                    props.navbarColor === "dark" && props.navbarType !== "static",
                  "d-none": props.navbarType === "hidden" && !props.horizontal,
                  "floating-nav":
                    (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
                  "navbar-static-top":
                    props.navbarType === "static" && !props.horizontal,
                  "fixed-top": props.navbarType === "sticky" || props.horizontal,
                  "scrolling": props.horizontal && props.scrolling

                }
              )}
            >
              <div className="navbar-wrapper">
                <div className="navbar-container content">
                  <div
                    className="navbar-collapse d-flex justify-content-between align-items-center"
                    id="navbar-mobile"
                  >
                    <div className="bookmark-wrapper">
                      <img src={logoGeno} alt="Genobank" width="150px"/>
                    </div>
                    <div className="d-flex" style={{marginRight:'3rem'}}>
                      <p>{web3ctx.activeWallet}</p>
                      </div>
                  </div>
                </div>
              </div>
            </Navbar>
          </React.Fragment>
        )}
      </AppContext.Consumer>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps, {
  logoutWithJWT,
  logoutWithFirebase,
  useAuth0
})(ThemeNavbar)
